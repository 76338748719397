$bg-sizes: contain, cover, unset;
$bg-position: bottom, left, center, right, top;
$bg-xy: x, y;
$bp: sm, md, lg, xl, xxl, xxxl, k2;

@for $i from 1 through 200 {
  .bg-s-#{$i} {
    background-size: #{$i}#{'%'};
  }
}

@each $bg-size in $bg-sizes {
  .bg-s-#{$bg-size} {
    background-size: $bg-size;
  }
}

@each $breakpoint in $bp {
  @for $i from 1 through 200 {
    @include media-breakpoint-up($breakpoint) {
      .bg-s-#{$breakpoint}-#{$i} {
        background-size: #{$i}#{'%'};
      }
    }
  }
}

@each $breakpoint in $bp {
  @each $bg-size in $bg-sizes {
    @include media-breakpoint-up($breakpoint) {
      .bg-s-#{$breakpoint}-#{$bg-size} {
        background-size: $bg-size;
      }
    }
  }
}

@for $i from -100 through 100 {
  .bg-p-#{$i} {
    background-position: #{$i}#{'%'};
  }
  @each $xy in $bg-xy {
    .bg-p-#{$xy}-#{$i} {
      background-position-#{$xy}: #{$i}#{'%'};
    }
  }
}

@each $breakpoint in $bp {
  @for $i from -100 through 100 {
    @include media-breakpoint-up($breakpoint) {
      .bg-p-#{$breakpoint}-#{$i} {
        background-position: #{$i}#{'%'};
      }
      @each $xy in $bg-xy {
        .bg-p-#{$xy}-#{$breakpoint}-#{$i} {
          background-position-#{$xy}: #{$i}#{'%'};
        }
      }
    }
  }
}

@for $i from -1000 through 1000 {
  .bg-p-#{$i}px {
    background-position: #{$i}#{'px'};
  }
  @each $xy in $bg-xy {
    .bg-p-#{$xy}-#{$i}px {
      background-position-#{$xy}: #{$i}#{'px'};
    }
  }
}

@each $breakpoint in $bp {
  @for $i from -1000 through 1000 {
    @include media-breakpoint-up($breakpoint) {
      .bg-p-#{$breakpoint}-#{$i}px {
        background-position: #{$i}#{'px'};
      }
      @each $xy in $bg-xy {
        .bg-p-#{$xy}-#{$breakpoint}-#{$i}px {
          background-position-#{$xy}: #{$i}#{'px'};
        }
      }
    }
  }
}

@each $position in $bg-position {
  .bg-p-#{$position} {
    background-position: $position;
  }
  @each $xy in $bg-xy {
    .bg-p-#{$xy}-#{$position} {
      background-position-#{$xy}: $position;
    }
  }
}

@each $breakpoint in $bp {
  @each $position in $bg-position {
    @include media-breakpoint-up($breakpoint) {
      .bg-p-#{$breakpoint}-#{$position} {
        background-position: $position;
      }
      @each $xy in $bg-xy {
        .bg-p-#{$xy}-#{$breakpoint}-#{$position} {
          background-position-#{$xy}: $position;
        }
      }
    }
  }
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

@keyframes transformStyle {
  from {transform-style: inherit;z-index: 1}
  to {}
}
