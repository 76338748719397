@import "brother1816-printed";
@import "brother1816-book";

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/Brother1816/Brother1816-ExtraBoldItalic.eot');
  src: local('Brother 1816 ExtraBold Italic'), local('Brother1816-ExtraBoldItalic'),
  url('../fonts/Brother1816/Brother1816-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816-ExtraBoldItalic.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816-ExtraBoldItalic.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/Brother1816/Brother1816-ExtraBold.eot');
  src: local('Brother 1816 ExtraBold'), local('Brother1816-ExtraBold'),
  url('../fonts/Brother1816/Brother1816-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816-ExtraBold.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816-ExtraBold.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/Brother1816/Brother1816-BlackItalic.eot');
  src: local('Brother 1816 Black Italic'), local('Brother1816-BlackItalic'),
  url('../fonts/Brother1816/Brother1816-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816-BlackItalic.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816-BlackItalic.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/Brother1816/Brother1816-Black.eot');
  src: local('Brother 1816 Black'), local('Brother1816-Black'),
  url('../fonts/Brother1816/Brother1816-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816-Black.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816-Black.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/Brother1816/Brother1816-Bold.eot');
  src: local('Brother 1816 Bold'), local('Brother1816-Bold'),
  url('../fonts/Brother1816/Brother1816-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816-Bold.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816-Bold.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/Brother1816/Brother1816-BoldItalic.eot');
  src: local('Brother 1816 Bold Italic'), local('Brother1816-BoldItalic'),
  url('../fonts/Brother1816/Brother1816-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816-BoldItalic.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816-BoldItalic.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/Brother1816/Brother1816-Medium.eot');
  src: local('Brother 1816 Medium'), local('Brother1816-Medium'),
  url('../fonts/Brother1816/Brother1816-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816-Medium.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816-Medium.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/Brother1816/Brother1816-MediumItalic.eot');
  src: local('Brother 1816 Medium Italic'), local('Brother1816-MediumItalic'),
  url('../fonts/Brother1816/Brother1816-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816-MediumItalic.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816-MediumItalic.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/Brother1816/Brother1816-Light.eot');
  src: local('Brother 1816 Light'), local('Brother1816-Light'),
  url('../fonts/Brother1816/Brother1816-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816-Light.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816-Light.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/Brother1816/Brother1816-LightItalic.eot');
  src: local('Brother 1816 Light Italic'), local('Brother1816-LightItalic'),
  url('../fonts/Brother1816/Brother1816-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816-LightItalic.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816-LightItalic.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/Brother1816/Brother1816-Thin.eot');
  src: local('Brother 1816 Thin'), local('Brother1816-Thin'),
  url('../fonts/Brother1816/Brother1816-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816-Thin.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816-Thin.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/Brother1816/Brother1816-ThinItalic.eot');
  src: local('Brother 1816 Thin Italic'), local('Brother1816-ThinItalic'),
  url('../fonts/Brother1816/Brother1816-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816-ThinItalic.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816-ThinItalic.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/Brother1816/Brother1816-Regular.eot');
  src: local('Brother 1816 Regular'), local('Brother1816-Regular'),
  url('../fonts/Brother1816/Brother1816-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816-Regular.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816-Regular.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/Brother1816/Brother1816-Regulartalic.eot');
  src: local('Brother 1816 Regular talic'), local('Brother1816-Regulartalic'),
  url('../fonts/Brother1816/Brother1816-Regulartalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816-Regulartalic.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816-Regulartalic.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816-Regulartalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
