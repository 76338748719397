
@font-face {
  font-family: 'Brother1816 Printed';
  src: url('../fonts/Brother1816/Brother1816Printed-Black.eot');
  src: local('Brother1816 Printed Black'), local('Brother1816Printed-Black'),
  url('../fonts/Brother1816/Brother1816Printed-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816Printed-Black.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816Printed-Black.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816Printed-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Brother1816 Printed';
  src: url('../fonts/Brother1816/Brother1816Printed-Bold.eot');
  src: local('Brother1816 Printed Bold'), local('Brother1816Printed-Bold'),
  url('../fonts/Brother1816/Brother1816Printed-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816Printed-Bold.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816Printed-Bold.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816Printed-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Brother1816 Printed';
  src: url('../fonts/Brother1816/Brother1816Printed-RegularItali.eot');
  src: local('Brother1816 Printed Regular Italic'), local('Brother1816Printed-RegularItali'),
  url('../fonts/Brother1816/Brother1816Printed-RegularItali.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816Printed-RegularItali.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816Printed-RegularItali.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816Printed-RegularItali.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Brother1816 Printed';
  src: url('../fonts/Brother1816/Brother1816Printed-ExtraBoldIta.eot');
  src: local('Brother1816 Printed ExtraBold Italic'), local('Brother1816Printed-ExtraBoldIta'),
  url('../fonts/Brother1816/Brother1816Printed-ExtraBoldIta.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816Printed-ExtraBoldIta.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816Printed-ExtraBoldIta.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816Printed-ExtraBoldIta.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Brother1816 Printed';
  src: url('../fonts/Brother1816/Brother1816Printed-Regular.eot');
  src: local('Brother1816 Printed Regular'), local('Brother1816Printed-Regular'),
  url('../fonts/Brother1816/Brother1816Printed-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816Printed-Regular.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816Printed-Regular.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816Printed-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brother1816 Printed';
  src: url('../fonts/Brother1816/Brother1816Printed-ThinItalic.eot');
  src: local('Brother1816 Printed Thin Italic'), local('Brother1816Printed-ThinItalic'),
  url('../fonts/Brother1816/Brother1816Printed-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816Printed-ThinItalic.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816Printed-ThinItalic.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816Printed-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Brother1816 Printed';
  src: url('../fonts/Brother1816/Brother1816Printed-BoldItalic.eot');
  src: local('Brother1816 Printed Bold Italic'), local('Brother1816Printed-BoldItalic'),
  url('../fonts/Brother1816/Brother1816Printed-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816Printed-BoldItalic.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816Printed-BoldItalic.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816Printed-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Brother1816 Printed';
  src: url('../fonts/Brother1816/Brother1816Printed-BlackItalic.eot');
  src: local('Brother1816 Printed Black Italic'), local('Brother1816Printed-BlackItalic'),
  url('../fonts/Brother1816/Brother1816Printed-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816Printed-BlackItalic.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816Printed-BlackItalic.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816Printed-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Brother1816 Printed';
  src: url('../fonts/Brother1816/Brother1816Printed-MediumItalic.eot');
  src: local('Brother1816 Printed Medium Italic'), local('Brother1816Printed-MediumItalic'),
  url('../fonts/Brother1816/Brother1816Printed-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816Printed-MediumItalic.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816Printed-MediumItalic.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816Printed-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Brother1816 Printed';
  src: url('../fonts/Brother1816/Brother1816Printed-Book.eot');
  src: local('Brother1816 Printed Book'), local('Brother1816Printed-Book'),
  url('../fonts/Brother1816/Brother1816Printed-Book.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816Printed-Book.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816Printed-Book.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816Printed-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brother1816 Printed';
  src: url('../fonts/Brother1816/Brother1816Printed-LightItalic.eot');
  src: local('Brother1816 Printed Light Italic'), local('Brother1816Printed-LightItalic'),
  url('../fonts/Brother1816/Brother1816Printed-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816Printed-LightItalic.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816Printed-LightItalic.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816Printed-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Brother1816 Printed';
  src: url('../fonts/Brother1816/Brother1816Printed-Thin.eot');
  src: local('Brother1816 Printed Thin'), local('Brother1816Printed-Thin'),
  url('../fonts/Brother1816/Brother1816Printed-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816Printed-Thin.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816Printed-Thin.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816Printed-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Brother1816 Printed';
  src: url('../fonts/Brother1816/Brother1816Printed-Medium.eot');
  src: local('Brother1816 Printed Medium'), local('Brother1816Printed-Medium'),
  url('../fonts/Brother1816/Brother1816Printed-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816Printed-Medium.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816Printed-Medium.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816Printed-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Brother1816 Printed';
  src: url('../fonts/Brother1816/Brother1816Printed-Light.eot');
  src: local('Brother1816 Printed Light'), local('Brother1816Printed-Light'),
  url('../fonts/Brother1816/Brother1816Printed-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816Printed-Light.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816Printed-Light.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816Printed-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Brother1816 Printed';
  src: url('../fonts/Brother1816/Brother1816Printed-BookItalic.eot');
  src: local('Brother1816 Printed Book Italic'), local('Brother1816Printed-BookItalic'),
  url('../fonts/Brother1816/Brother1816Printed-BookItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816Printed-BookItalic.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816Printed-BookItalic.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816Printed-BookItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Brother1816 Printed';
  src: url('../fonts/Brother1816/Brother1816Printed-ExtraBold.eot');
  src: local('Brother1816 Printed ExtraBold'), local('Brother1816Printed-ExtraBold'),
  url('../fonts/Brother1816/Brother1816Printed-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Brother1816/Brother1816Printed-ExtraBold.woff2') format('woff2'),
  url('../fonts/Brother1816/Brother1816Printed-ExtraBold.woff') format('woff'),
  url('../fonts/Brother1816/Brother1816Printed-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

