/* You can add global styles to this file, and also import other style files */
* {
  outline: none;
  box-sizing: border-box;
}
html, body { height: 100%;}

.vh-50 {
  height: 50vh;
}
