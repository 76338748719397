@import 'variable';
@import 'fonts';
@import 'custom-material';
@import 'bootstrap';
@import "globals";

body { margin: 0; font-family: $bodyfont; font-weight: $font-weight300; font-style: normal; background: #F1F1F1; color:$color-text-grey;font-size: var(--font-size18); }
html {
  padding-top: 96px;
  @media screen and (max-width: $mobile) {
    padding-top: 75px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $headingfont !important;
  font-weight: $font-weight700;
}

a {
  text-decoration: none;
}

b, strong {
  font-weight: $font-weight-bold;
}

.flex-title {
  @include media-breakpoint-down(md) {
    font-size: 40px !important;
  }
  @include media-breakpoint-down(sm) {
    font-size: 32px !important;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 3.1vw !important;
  }
}

.flex-subtitle {
  @include media-breakpoint-down(md) {
    font-size: 24px !important;
  }
  @include media-breakpoint-down(sm) {
    font-size: 20px !important;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 1.9vw !important;
  }
}

.flex-content {
  @include media-breakpoint-up(xxl) {
    font-size: 1.35vw !important;
  }
}

.flex-p-text {
  font-size: var(--font-size18);
  @include media-breakpoint-up(lg) {
    font-size: var(--font-size24);
  }
}

.fs-6 {
  @include media-breakpoint-down(sm) {
    font-size: 1.125rem !important;
  }
  @include media-breakpoint-up(xl) {
    font-size: 1.125rem !important;
  }
}

@include media-breakpoint-up(xxxl) {
  .fs-3 {
    font-size: 2.25rem !important;
  }
}

.flex-fs-80 {
  @include media-breakpoint-up(lg) {
    font-size: 52px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 64px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 70px;
  }
  @include media-breakpoint-up(xxxl) {
    font-size: 80px !important;
  }
}

.flex-fs-64 {
  @include media-breakpoint-up(xl) {
    font-size: 48px;
  }
  @include media-breakpoint-up(xxl) {
    font-size: 54px;
  }
  @include media-breakpoint-up(xxxl) {
    font-size: 64px !important;
  }
}

.dashed-vr {
  opacity: 1;
  background-image: repeating-linear-gradient( to bottom, transparent, transparent 5px, black 10px, black 14px );
  width: 1px;
}

.dashed-hz {
  opacity: 1;
  background-image: repeating-linear-gradient(
                  to right,
                  transparent,
                  transparent 5px,
                  black 10px,
                  black 14px
  );
  height: 1px;
  border: none;
}


  .slider-bg {
    &-txt {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      @media screen and (max-width: $mobile) {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        background-color: #fff;
      }
      .container {
        position: relative;
        display: flex;
        @media screen and (max-width: $mobile) {
          display: block;
        }
      }
      .slider-bg-txt-ma {
        margin-left: auto;
        color: $color-text-light;
        @media screen and (max-width: $mobile) {
          padding: 25px 10px;
        }
        h1 {
          font-size: var(--font-size80);
          margin: 0;
          position: relative;
          strong {
            position: relative;
            display: block;
            z-index: 5;
            font-weight: $font-weight700;
          }
          br {
            @media screen and (max-width: $mobile) {
              display: none;
            }
          }
          span {
            color: #1DF7F6;
            font-size: var(--font-size400);
            line-height:var(--font-size400);
            z-index: 0;
            position: absolute;
            top: 39%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media screen and (max-width: $mobile) {
              left: 50%;
              top: 50%;
              font-size: 200px;
              line-height: 200px;
            }
            @include media-breakpoint-down(sm) {
              top: 60%;
            }
          }
        }
      }
    }
  }


.flex-btn {
  border-radius: 30px;
  padding: 0.775rem 1.75rem;
}

.flex-menu-dropdown {
  background-blend-mode: multiply;
  background-color: rgba(255, 255, 255, 0.1);
  --mdc-theme-text-primary-on-background: #707070;
  .mat-mdc-menu-content {
    padding: 0;
    background-blend-mode: multiply;
    background-color: rgba(255, 255, 255, 0.6);
    .mat-mdc-menu-item:not(:last-child) {
      border-bottom: 1px solid #707070;
      color: #707070;
    }
    .mat-mdc-menu-item {
      &.flex-child-active {
        --mdc-theme-text-primary-on-background: #333;
        font-weight: bold;
      }
    }
  }
}
.container {
  max-width: 100%;
  max-width: 1300px;
  width: 90vw;
  @media screen and (max-width: 1300px) {
    width: 90vw;
  }
  @media screen and (max-width: $mobile) {
    width: 100%;
  }
}

.title-main {
  margin-bottom: 40px;
  @media screen and (max-width: $mobile) {
    margin-bottom: 25px;
  }
  h2 {
    font-size: var(--font-size60);;
    color: $color-text-light;
    margin-bottom: 15px;
    @media screen and (max-width: $mobile) {
      word-break: break-word;
    }
  }
  p {
    font-size: var(--font-size18);
    @include media-breakpoint-up(lg) {
      font-size: var(--font-size24);
    }
  }
}
.title-main-sm {
  margin-bottom: 30px;
  h2 {
    font-size: var(--font-size42);;
    color: $color-text-light;
    margin-bottom: 15px;
  }
}
.box-panel {
  background: #FFFFFF;
  box-shadow: 0px 12px 9px rgba(0, 0, 0, 0.160784);
  border-radius: 43px;
  margin-bottom: calc(var(--bs-gutter-x) * 1);
  position: relative;
  &-title {
    margin-bottom: 30px;
    h2 {
      font-size: var(--font-size36);
      color: $color-text-light;
      margin-bottom: 10px;
      // @include media-breakpoint-up(lg) {
      //   font-size: var(--font-size40);
      // }
    }
    p {
      font-size: var(--font-size18);
      @include media-breakpoint-up(lg) {
        font-size: var(--font-size18);
      }
    }
  }
  &-body {
    padding: 2vw;
    @media screen and (max-width: $mobile) {
      padding: 25px;
    }
  }
}
.pad-30 {
  padding: 30px 0;
  @media screen and (max-width: $mobile) {
    padding: 20px 0;
  }
}
.img-full {
  width: 100%;
}
.pa-bt-0 {
  padding-bottom: 0 !important;
}
.mar-bt-30 {
  margin-bottom: 30px;
}
.row-col-flex {
  > * {
      display: flex;
      align-items: stretch;
      @media screen and (max-width: $mobile) {
        display: block;
        align-items: normal;
      }
  }
}
.row-col-center {
  align-items: center;
}
.order-12 {
  order: 12;
  @media screen and (max-width: $mobile) {
    order:-1;
  }
}
.go-btn {
  text-align: center;
  display: block;
  padding: 0;
  color: #0066CC;
}
.img-bottom-panel {
  img {
    // border-radius: 0 0 43px 43px;
  }
}
.link-a {
  color: $color-text-grey;
  text-decoration: underline;
}
.img-border-r {
  border-radius: 43px;
}
.mobile-show, .mobile-show-768 {
  display: none !important;
}
@media screen and (max-width: $mobile) {
  .mobile-show {
    display: block !important;
  }
  .desktop-show {
    display: none !important;
  }
}
@media screen and (max-width: 768px) {
  .mobile-show-768 {
    display: block !important;
  }
  .desktop-show-768 {
    display: none !important;
  }
}
.row-bms-mobile {
  @media screen and (max-width: $mobile) {
      background: #FFFFFF;
      box-shadow: 0px 12px 9px rgb(0 0 0 / 16%);
      border-radius: 43px;
      margin: 0;
      margin-bottom: 15px;
      position: relative;
      > * {
          padding: 0;
      }
      .box-panel {
          box-shadow:none;
          border-radius: 0;
          background:none;
          margin: 0;
          @media screen and (max-width: $mobile) {
            .box-panel-title {
              margin-bottom: 0;
            }
          }
          .box-panel-body {
            @media screen and (max-width: $mobile) {
              padding-bottom: 0px;
            }
          }
      }
  }
}
.carousel {
  &.no-padding {
    padding-bottom: 0;
  }
  .carousel-indicators {
      bottom: -20px;
      button {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #000;
          opacity: 0.5;
          &.active {
              opacity: 1;
          }
      }
  }
}
.slick-slide img {
  margin: auto;
}
.slick-dots {
  bottom: -35px !important;
  li {
    margin: 0  !important;
  }
}

.slider-arrow {
  .slick-prev, .slick-next {
      bottom: -66px;
      top: auto;
      left: 50%;
      background-image: url(../images/icons/arrow-slider.svg) !important;
      background-size: 100%;
      background-repeat: no-repeat;
      width: 25px;
      height: 25px;
      &:before {
        display: none;
      }
  }
  .slick-disabled {
    opacity: 0.5;
  }
  .slick-prev {
    margin-left: -48px;
    transform: rotate(180deg);
    bottom: -53px;
  }
  .slick-next {
      margin-left: 6px;
  }
}
.banner-faq {
  position: relative;
  &:before {
    content: '';
    background-color: #000000;
    opacity: 0.1;
    width: 100%;
    position: absolute;
    height: 100%;
  }
  &-txt {
    position: static;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 8vw;
    h1 {
      color: #fff;
      font-size: var(--font-size100);
    }
    @include media-breakpoint-up(md) {
      position: absolute;
    }
  }
  &-img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }
}
.faq-page {
  margin: 2vw 0;
  .box-panel-faq {
    padding: 3vw;
    > h2 {
      font-size: var(--font-size50);
      padding-bottom: 20px;
      padding-top: 20px;
      border-bottom: 1px solid #707070;
      @include media-breakpoint-up(md) {
        padding-top: 0;
        padding-bottom: 35px;
      }
    }
  }
  .accordion {
    border:0;
    border-radius: 0;
    color:$color-text-grey;
    font-size: var(--font-size20);
    &-item {
      border:0;
      border-radius: 0 !important;
      border-bottom: 1px solid #707070;
    }
    &-body {
      padding: 0 0 30px 0;
      font-size: var(--font-size20);
      ul.dash {
        list-style-type: none;
        li {
          &:before {
            display: inline-block;
            content: "-";
            width: 1em;
            margin-left: -1em;
          }
        }
      }
    }
    &-button {
      border:0;
      padding: 25px 0;
      border-radius: 0 !important;
      background: none;
      font-weight: 500;
      box-shadow: none;
      font-size: var(--font-size20);
      &:not(.collapsed) {
        color:$color-text-grey;
        &::after {
          background-image: var(--bs-accordion-btn-icon);
        }
      }
    }
  }

  .ac-faq {
    margin-top:20px;
    &-item {
      background: #F8F8F8;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.160784);
      border-radius: 20px;
      padding: 20px;
      display: inline-block;
      margin-right: 20px;
      cursor: pointer;
      &:last-child {
        margin: 0;
      }
    }
    .check-fb {
      width: 28px;
      height: 28px;
      background:url(/assets/images/icons/check-circle.svg) no-repeat;
      background-size: 100%;
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
  .ask-for-more {
    background-color: #F8F8F8;
    border-radius: 20px;
    margin-top: 50px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
}

/*******************/
/*Snack-bar*/
/*******************/
mat-snack-bar-container{
  &.success {
    .mdc-snackbar__surface {
      background-color: #47A7EE !important;
      color: #FFFFFF !important;
    }
  }

  &.error {
    .mdc-snackbar__surface {
      background-color: #e6294b !important;
      color: #FFFFFF !important;
    }
  }
}
