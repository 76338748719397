@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css2?family=Google+Sans&display=swap');
@import "bootstrap/scss/functions";
//@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

$bodyfont: "Brother 1816", "Google Sans", sans-serif !important;
$headingfont: "Brother 1816", "Google sans", sans-serif;
$primary: #1DF7F6;
$link-color: #0066CC;

// Media 
$mobile: 768px;

// color 
$color-text-grey: #030303;
$color-text-light: #363636;
$color-blue: rgba(29, 247, 246, 1);

$enable-smooth-scroll: false;

$grid-breakpoints: (
        xs: 0,
        xsm: 375px,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
        xxxl: 1600px,
        k2: 1900px
);

$container-max-widths: (
        xsm: 350px,
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1320px,
        xxxl: 1550px,
        k2: 1880px
);
// font size

:root {
  --font-size400: 400px;
  --font-size160: 8.33333vw;
  --font-size150: 150px;
  --font-size100: 100px;
  --font-size80: 80px;
  --font-size50: 50px;
  --font-size60: 60px;
  --font-size42: 42px;
  --font-size40: 40px;
  --font-size36: 36px;
  --font-size24: 24px;
  --font-size20: 20px;
  --font-size18: 18px;
}

@include media-breakpoint-down(sm) {
  :root {
    --font-size160: calc(8.33333vw * 2);
  }
}

@include media-breakpoint-down(lg) {
  :root {
    --font-size160: calc(8.33333vw * 1.5);
  }
}

@media screen and (max-width: 2000px) {
  :root {
    --font-size400: 20.833vw;
    --font-size150: 7.813vw;
    --font-size100: 5.208vw;
    --font-size80: 4.167vw;
    --font-size50: 2.604vw;
    --font-size60: 3.125vw;
    --font-size42: 2.188vw;
    --font-size40: 2.083vw;
    --font-size36: 1.875vw;
    --font-size24: 1.250vw;
    --font-size20: 1.042vw;
  }
}

@media screen and (max-width: 1400px) {
  :root {
    --font-size18: 16px;
  }
}

@media screen and (max-width: 1200px) {
  :root {
    --font-size400: 330px;
    --font-size150: 80px;
    --font-size100: 60px;
    --font-size80: 50px;
    --font-size60: 33px;
    --font-size50: 31px;
    --font-size42: 28px;
    --font-size36: 24px;
    --font-size24: 20px;
    --font-size20: 18px;
  }
}

@media screen and (max-width: 500px) {
  :root {
    --font-size400: 150px;
    --font-size150: 60px;
    --font-size100: 50px;
    --font-size80: 40px;
    --font-size60: 30px;
    --font-size50: 28px;
    --font-size36: 20px;
  }
}
@media screen and (max-width: 390px) {
  :root {
    --font-size100: 42px;
    --font-size80: 32px;
    --font-size20: 14px;
  }
}
// font weight
$font-weight300: 300 !important;
$font-weight400: 400 !important;
$font-weight700: 700 !important; 
